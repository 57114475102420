import { SET_SEARCH_QUERY } from "./appActions";

const initialState = {
  cmsProducts: [],
  searchQuery: "",
  sailingAreas: [],
  offerEmailTemplates: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_QUERY:
      return { ...state, searchQuery: action.searchQuery };
    default:
      return { ...state };
  }
};
