import React from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import rootReducer from "./rootReducer";
import { GlobalProvider } from "../contexts/global-provider";

const configureStore = () => createStore(rootReducer, applyMiddleware(thunk));

export default ({ element }) => <Provider store={configureStore()}><GlobalProvider>{element}</GlobalProvider></Provider>;
