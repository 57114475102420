const environment = {
  host: "https://tide-thalassa8624.tidesoftware.be",
  builds: [
    {
      officeId: 2,
      agentId: 9,
      name: "SelectTogether",
      key: "select-together",
      path: "select-together",
      url: "https://www.selectcruises.be/",
      env: "production",
    },
  ],
};

module.exports = {
  environment,
};
