import React, { createContext, useState } from "react";
import { environment } from "../../environments/environment";

const initialState = {
  searchSelection: {
    selectedCruiseTypes: [],
    selectedSailingAreas: [],
    selectedDates: [],
    selectedDurations: [],
    shippingCompanyId: undefined,
  },
  sailingAreas: [],
  navigation: undefined,
  privacy: undefined,
  theme: environment.builds[0].key,
};

const GlobalContext = createContext(initialState)

export const GlobalProvider = (props) => {
  const [searchSelection, setSearchSelection] = useState(initialState.searchSelection);
  const [sailingAreas, setSailingAreas] = useState(initialState.sailingAreas);
  const [navigation, setNavigation] = useState(initialState.navigation);
  const [privacy, setPrivacy] = useState(initialState.privacy);
  const [theme] = useState(initialState.theme);
  
  return (
    <GlobalContext.Provider
    value={{
      searchSelection,
      sailingAreas,
      navigation,
      privacy,
      theme,
      setSearchSelection,
      setSailingAreas,
      setNavigation,
      setPrivacy
    }}>
      {props.children}
    </GlobalContext.Provider>
  )
}

export default GlobalContext;
